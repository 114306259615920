html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  background-position: center top;
  background-repeat: no-repeat;
  overscroll-behavior-y: contain;
}

#root {
  height: 100%;
  margin: auto auto auto auto;
}

div {
  flex: 0;
  display: flex;
  flex-direction: column;
}

.ai-tracking-wrapper {
  flex-grow: inherit;
}
